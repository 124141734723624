<script setup lang="ts">
const props = withDefaults(defineProps<{
  orientation?: 'left' | 'right'
  direction?: 'up' | 'down'
  icon?: string | undefined
  label?: string
  title?: string
  color?: Color
  contentWrapper?: boolean
  forceOpen?: boolean
}>(), {
  orientation: 'left',
  direction: 'down',
  icon: undefined,
  label: '',
  title: '',
  color: 'hsl(var(--panel))',
  contentWrapper: true,
  forceOpen: false,
})
const emit = defineEmits<{
  (event: 'open'): void
  (event: 'close'): void
}>()

const container = ref(undefined)
const isOpen = ref(false)
const isOpenComp = computed(() => isOpen.value || props.forceOpen)
const containerClasses = computed(() => ({
  open: isOpenComp,
  left: props.orientation === 'left',
  right: props.orientation === 'right',
  up: props.direction === 'up',
  down: props.direction === 'down',
}))
const containerStyles = computed(() => ({
  '--color': props.color,
}))

function open(): void {
  isOpen.value = true
  emit('open')
}

function close(): void {
  isOpen.value = false
  emit('close')
}

function toggle(): void {
  if (!isOpen.value)
    open()
  else close()
}

onClickOutside(container, () => {
  if (isOpen.value)
    close()
})
defineExpose({
  open,
  close,
  toggle,
})
</script>

<template>
  <div
    ref="container"
    class="dropdown-container"
    :class="containerClasses"
    :style="containerStyles"
  >
    <div
      v-if="icon"
      class="action-btn-container"
      :title="title"
      @click="toggle()"
    >
      <slot name="toggle">
        <span class="action-btn">
          <Icon :name="isOpenComp ? 'cross' : icon" />
        </span>
        <label v-if="label">{{ label }}</label>
      </slot>
    </div>
    <div
      v-if="isOpenComp"
      :class="contentWrapper ? 'dropdown-content' : undefined"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-container {
  position: relative;
  align-self: center;
  display: flex;
  flex-flow: column;
  border-radius: $border-radius-round;
  &.open {
    .action-btn-container {
      .action-btn {
        z-index: 100;
        @media (hover: hover) {
          &:hover {
            background-color: hsl(var(--element));
          }
        }
      }
    }
  }
  &.left {
    :deep(.dropdown-content) {
      right: 0;
    }
  }
  &.right {
    :deep(.dropdown-content) {
      left: 0;
      .dropdown-item {
        display: flex;
        justify-content: space-between;
        padding-left: 1.25rem;
        .icon-wrap  {
          margin-left: 1.5rem;
          font-size: $font-size-icon;
          svg {
            margin: 0;
            padding: 0 .5rem;
          }
        }
      }
    }
  }
  &.down {
    :deep(.dropdown-content) {
      top: 2.75rem;
    }
  }
  &.up {
    :deep(.dropdown-content) {
      bottom: 2.75rem;
    }
  }
  .action-btn-container {
    display: flex;
    // align-self: flex-end;
    align-items: center;
    width: fit-content;
    height: fit-content;
    border-radius: $border-radius-round;
    cursor: pointer;
    .action-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $button-size;
      height: $button-size;
      padding: 0;
      color: hsl(var(--text));
      border-radius: $border-radius-round;
      font-size: $font-size-icon-mobile;
      @media #{$desktop} {
        font-size: $font-size-icon;
      }
      svg {
        font-size: $font-size-icon-mobile;
        @media #{$desktop} {
          font-size: $font-size-icon;
        }
      }
      @media (hover: hover) {
        &:hover {
          color: hsl(var(--text));
        }
      }
    }
    label {
      margin: 0 1rem;
      cursor: pointer;
    }
  }
  :deep(.dropdown-content) {
    @include hidescrollbar;
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    width: max-content;
    max-height: calc(100vh - #{$menu-size-mobile} - .5rem);
    padding: $border-radius-lg 0;
    background-color: var(--color);
    border-radius: $border-radius-lg;
    box-shadow: var(--shadow-panel);
    overflow-y: scroll;
    @media #{$desktop} {
      max-height: calc(100vh - #{$menu-size} - .5rem);
    }
    .dropdown-item {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      // min-width: max-content;
      // margin: 0 -1rem;
      padding: .35rem .6rem .35rem 1.75rem;
      font-size: 1rem;
      font-weight: normal;
      box-sizing: border-box;
      // border-radius: $border-radius;
      .label {
        margin-right: .6rem;
      }
      .icon-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.8rem;
        height: 1.8rem;
        font-size: .75rem;
        border-radius: $border-radius-round;
      }
    }
    .dropdown-divider {
      width: calc(100% + 1rem);
      height: 1px;
      min-height: 1px;
      margin: $border-radius-lg -.5rem;
      background-color: hsl(var(--border));
    }
  }
}
</style>
